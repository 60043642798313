exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-other-page-jsx": () => import("./../../../src/templates/otherPage.jsx" /* webpackChunkName: "component---src-templates-other-page-jsx" */),
  "component---src-templates-project-archive-jsx": () => import("./../../../src/templates/projectArchive.jsx" /* webpackChunkName: "component---src-templates-project-archive-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */)
}

